var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.loading &&
      _vm.data &&
      _vm.data.properties &&
      _vm.data.properties.filed_content != 'Hide'
    ),expression:"\n      !loading &&\n      data &&\n      data.properties &&\n      data.properties.filed_content != 'Hide'\n    "}]}),_c('div',{staticStyle:{"display":"flex"},style:(_vm.getElementStyle)},[_c(_vm.selectedComponent || _vm.getSelectedComponent(),{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],tag:"component",style:({
        width: _vm.data.width ? `100%` : 'auto',
        color:
          _vm.data.styles && _vm.data.styles.label_color
            ? _vm.data.styles.label_color
            : '',
        'text-align': _vm.inputTextAlignment,
      }),attrs:{"data":{
        ..._vm.selectedField,
        integration_settings: _vm.selectedField.integration_settings || {},
        validations: {
          ..._vm.selectedField.validations,
          required: _vm.isRequired,
        },
        properties: {
          ..._vm.data.properties,
          hideLabel: _vm.data.properties.hideLabel,
        },
      },"form":_vm.form,"is-view":_vm.isView,"hasLabel":_vm.hasLabel && !_vm.data.properties.hideLabel,"fieldsData":_vm.fieldsData,"isFromDocument":_vm.isFromDocument,"isEntityVariable":true,"originalField":_vm.data,"formbuilderDetails":_vm.formbuilderDetails,"checkIsDisabled":_vm.checkIsDisabled,"parentFormData":_vm.parentFormData,"dataTableRowIndex":_vm.dataTableRowIndex,"entityDataList":_vm.entityDataList,"parentField":_vm.parentEntityFiled,"savingData":_vm.savingData,"autoFillEntityData":_vm.autoFillEntityData,"highlight":_vm.highlight,"entitiesToRefresh":_vm.entitiesToRefresh,"rules_hide":_vm.rules_hide,"labelAlignments":_vm.labelAlignments},on:{"entityDataUpdated":_vm.entityDataUpdated,"clearEntityFields":_vm.clearEntityFields,"applyFormRules":_vm.rulesEventEmitter,"onNewRowAdded":_vm.emitRowAdded,"updateTableData":_vm.updateTableFieldData}}),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }